<template>
<div class=" bg-blue-100 pt-10 pb-16">
<div class="text-center">
</div>
  <div class="flex flex-wrap px-4 sm:px-10 md:px-20 lg:px-24 xl:px-32 xxl:px-54 xxxl:px-80 mx-auto" :class="{loading: loading}">

    <div class="w-full md:w-full bg-blue-100" >
      <div class="flex flex-wrap relative overflow-hidden container mx-auto " :class="{loading: loading}" v-if="loading">
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6" >
          <div class=" h-64 bg-blue-100 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-blue-100 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-blue-100 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-blue-100 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-blue-100 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-blue-100 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-blue-100 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-blue-100 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-blue-100 my-4 w-2/3 mx-4">

          </div>
        </div>
      </div>

      <div v-else class="flex flex-wrap container mx-auto">
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 mb-2" v-for="product in products" >
                    <div class="mx-1 h-full  ">
                <div class="bg-white h-full item_hover">
          <product :product="product" @add-to-cart="addToCart" />
        </div>
        </div>
        </div>
      </div>
      <div class="my-4 text-center hidden md:block" v-if="typeof meta.pagination !== 'undefined'">
        <a href="#" @click.prevent="offset -= 12; getProducts(); scrollTo(0, 0);" v-if="offset > 0">&lt; Prev</a>
        <a href="#" @click.prevent="offset = 0; getProducts(); scrollTo(0, 0);" v-if="showFirst" class="mx-2 border-b">1</a>
        <span v-if="showFirst" class="px-2">...</span>
        <a href="#" @click.prevent="offset = (page-1)*12; getProducts(); (page == currentPage) ? '': scrollTo(0, 0);" v-for="page in pageList" class="mx-2" :class="{'border-b': page != currentPage, 'cursor-default': page == currentPage}">{{ page }}</a>
        <span v-if="showLast" class="px-2">...</span>
        <a href="#" @click.prevent="offset = (meta.pagination.total_pages-1)*12; getProducts(); scrollTo(0, 0);" v-if="showLast" class="mx-2 border-b">{{ meta.pagination.total_pages }}</a>
        <a href="#" @click.prevent="offset += 12; getProducts(); scrollTo(0, 0);" v-if="offset + 12 < meta.pagination.total">Next &gt;</a>
      </div>
      <div class="my-4 text-center md:hidden" v-if="typeof meta.pagination !== 'undefined'">
        <a href="#" @click.prevent="offset -= 12; getProducts(); scrollTo(0, 0);" v-if="offset > 0">&lt; </a>
        <a href="#" @click.prevent="offset = 0; getProducts(); scrollTo(0, 0);" v-if="showFirstMob" class="mx-2 border-b">1</a>
        <span v-if="showFirst" class="px-2">...</span>
        <a href="#" @click.prevent="offset = (page-1)*12; getProducts(); (page == currentPage) ? '': scrollTo(0, 0);" v-for="page in pageListMob" class="mx-2" :class="{'border-b': page != currentPage, 'cursor-default': page == currentPage}">{{ page }}</a>
        <span v-if="showLast" class="px-2">...</span>
        <a href="#" @click.prevent="offset = (meta.pagination.total_pages-1)*12; getProducts(); scrollTo(0, 0);" v-if="showLastMob" class="mx-2 border-b">{{ meta.pagination.total_pages }}</a>
        <a href="#" @click.prevent="offset += 12; getProducts(); scrollTo(0, 0);" v-if="offset + 12 < meta.pagination.total"> &gt;</a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'product-listing',
  data() {
    return {
      csrfName: window.csrfTokenName,
      csrfToken: window.csrfTokenValue,
      products: [],
      loading: false,
      meta: {},
      offset: 0,
    }
  },
  mounted() {
    var _this = this;


    this.getProducts();
  },
  computed: {
    pageList() {
        // No pages to show
        if (this.meta.pagination.total_pages === 0)
            return []
        if (this.meta.pagination.total_pages === 1)
            return [this.currentPage]
        if (this.meta.pagination.total_pages < 5)
            return Array.apply(null, Array(this.meta.pagination.total_pages)).map((val, index) => index+1)
        let min = Math.max(this.currentPage-2, 1);
        let max = Math.min(this.currentPage+2, this.meta.pagination.total_pages);
        min = Math.max(max-4, 1)
        max = Math.min(min+4, this.meta.pagination.total_pages)

        const pages = []
        for (let index = min; index <= max; index++)
            pages.push(index)
        return pages;
    },
    showFirst() {
        return this.pageList.length && !this.pageList.includes(1);
    },
    showLast() {
        return this.pageList.length && !this.pageList.includes(this.meta.pagination.total_pages);
    },
    pageListMob() {
        // No pages to show
        if (this.meta.pagination.total_pages === 1)
            return [this.currentPage]
        if (this.meta.pagination.total_pages < 5)
            return []
        let min = Math.max(this.currentPage-1, 1);
        let max = Math.min(this.currentPage+1, this.meta.pagination.total_pages);
        min = Math.max(max-2, 1)
        max = Math.min(min+2, this.meta.pagination.total_pages)

        const pages = []
        for (let index = min; index <= max; index++)
            pages.push(index)
        return pages;
    },
    showFirstMob() {
        return !this.pageListMob.includes(1);
    },
    showLastMob() {
        return !this.pageListMob.includes(this.meta.pagination.total_pages);
    },
    currentPage() {
        return Math.floor(this.offset/12)+1
    },
  },
  methods: {
      scrollTo() {
        window.scrollTo(0, 0);
      },
      scrollFollow(event, target, parent) {
          if (target) {
              if (target.scrollTop === 0) {
                  target.setAttribute('data-scroll', 'top');
                  parent.setAttribute('data-scroll', 'top');
              }
              else if (target.scrollHeight - target.scrollTop === target.clientHeight) {
                  target.setAttribute('data-scroll', 'bottom');
                  parent.setAttribute('data-scroll', 'bottom');
              } else {
                  target.setAttribute('data-scroll', '');
                  parent.setAttribute('data-scroll', '');
              }
          }
      },
    addToCart(id, qty) {
      this.$emit('add-to-cart', id, qty);
    },
    getProducts() {
      let _this = this;

      let query = {
        [this.csrfName]: this.csrfToken,
        offset: _this.offset,
      };

      this.loading = true;
      axios.get('/api/products', {
          params: query
        })
        .then(function(response) {
          _this.products = response.data.data;
          _this.meta = response.data.meta;
          console.log(_this.meta);
        })
        .catch(function(error) {
          console.log(error);
        })
        .then(function() {
          _this.loading = false;
        });
    }
  },
}
</script>

<style lang="scss" scoped>
.filters-single {
    .active {}
}

.plus{
  transform: rotate(45deg);
  transition: 0.2s;
  transition-timing-function: ease-in-out;
}

.close-plus {

.plus{
  transform: rotate(0deg);
  transition: 0.2s;
    transition-timing-function: ease-in-out;
}
}


.filter-outer {
    @media screen and (max-width: 767px) {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease;

        &.open {
            max-height: 2000px;
        }
    }
}

.filter-group {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease;

    .filter-wrapper.open & {
        opacity: 1;
        max-height: 800px;
        overflow-y: scroll;
        position: relative;



    }


}

.filter-wrapper {
   .filters-single.active, .filter-other.active{
    padding-left: 20px;
    position: relative;
    font-weight: bold;

    &:before{
      content: "";
      width: 5px;
      height: 5px;
      background-color: #fff;
      position: absolute;
      top:0;
      bottom:0;
      border-radius: 99px;
      margin-right: 8px;
      margin: auto;
      margin-left: -15px;
    }
  }
  .filter-single.active{
    position: relative;
    font-weight: bold;
      padding-left: 26px;
      &:before  {
      content:"";
      position: absolute;
      width:20px;
      background-repeat: no-repeat;
      height:20px;
      margin-right: 8px;
      background-position:center;
      pointer-events: none;
      cursor:pointer;
      left:0;
      background-image:url('/assets/static/close.svg');
  }
}
    h3 {
        cursor: pointer;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        svg {
            pointer-events: none;
            transition: transform 0.2s ease;
            path {
                stroke: currentColor;
            }
        }
    }
    &.open {
        h3 {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}


</style>
