import Vue from 'vue';
import "./css/main.scss";
import Components from "./components";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import axios from 'axios';
import EventBus from './EventBus'
import $ from 'jquery';
require('jquery-serializejson');
import VueCase from 'vue-case'
import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';

// import style
import 'swiper/css/swiper.css'


window.jquery = $;

Vue.use(VueAwesomeSwiper)
Vue.use(VueCase)
Vue.use(Croppa);
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// breakpoint where swiper will be destroyed
// and switches to a dual-column layout
const breakpoint = window.matchMedia( '(min-width:31.25em)' );
// keep track of swiper instances to destroy later
let mySwiper;
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
const breakpointChecker = function() {
   // if larger viewport and multi-row layout needed
   if ( breakpoint.matches === true ) {
      // clean up old instances and inline styles when available
      if ( mySwiper !== undefined ) mySwiper.destroy( true, true );
      // or/and do nothing
      return;
   // else if a small viewport and single column layout needed
   } else if ( breakpoint.matches === false ) {
      // fire small viewport version of swiper
      return enableSwiper();
   }
};




//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
const enableSwiper = function() {
   mySwiper = new Swiper ('.swiper-container', {
      loop: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      a11y: true,
      keyboardControl: true,
      grabCursor: true,
      // pagination
      pagination: '.swiper-pagination',
      paginationClickable: true,
   });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
// keep an eye on viewport size changes

if ($("#app").length) {
  window.$vueInstance = new Vue({
    el: "#app",
    delimiters: ["[[", "]]"],
    components: {
     LocalSwiper: VueAwesomeSwiper.swiper,
     LocalSlide: VueAwesomeSwiper.swiperSlide,
   },
   data() {
    return {
      csrfName: window.csrfTokenName,
      csrfToken: window.csrfTokenValue,
      couponError: '',
      showNav: false,
      discussionOpen: false,
      projects: window.projects,
      currentProject: 0,
      loan: {},
      myCroppa: {},
      swiperOptionTop: {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 0,
        dynamicBullets:true,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
        breakpoints:{
          600: {
             spaceBetween: 10,
             dynamicBullets:false,
     },
        }
      },
      swiperOptionCollection: {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      },
      department: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        navigation: {
          nextEl: '.next_dep',
          prevEl: '.prev_dep'
        },
      },
      departmentNav: {
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: true,
        loop:true,
      },
      departmentsFull: {
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: true,
        loop:true,


      },
      collection:{
        slidesPerView: 'auto',
        spaceBetween: 0,
        centeredSlides: true,
      }
    }
  },
  mounted() {
    if (typeof this.$refs.swiperTop !== 'undefined') {
      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.$swiper
        const swiperThumbs = this.$refs.swiperThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      })
    }


  },
  methods: {
    uploadCroppedImage() {
    this.myCroppa.generateBlob(
          blob => {
            // write code to upload the cropped image file (a file is a blob)
          },
          'image/jpeg',
          0.8
        ); // 80% compressed jpeg file
    },
    ago(ts) {
      let now = +new Date() / 1000;
      let seconds, minutes, hours, days, weeks;
      seconds = (now - ts);
      minutes = seconds / 60;
      hours = minutes / 60;
      days = hours / 24;
      weeks = days / 7;

      if (minutes < 5) {
        return 'just now'
      } else if (hours < 1) {
        return `${Math.floor(minutes)}m ago`
      } else if (days < 1) {
        return `${Math.floor(hours)}h ago`
      } else if (weeks < 1) {
        return `${Math.floor(days)}d ago`
      } else {
        return `${Math.floor(weeks)}w ago`
      }
    },
    pretty(amount) {
          return (amount / 100.0).toFixed(0);
      },
      condense(firstName, lastName) {
          return ([firstName, lastName]).map((n, index) => !index ? n : `${n.slice(0,1)}.`).join(' ')
      },
      updateCurrentProject(project) {
          this.currentProject = project
      }
  },
  });
} else {
    alert("AARRRRGGGHHHHH!!!!")
}
$('#next_dep').hover(
       function(){ $('.department_swiper').addClass('hovered-next') },
       function(){ $('.department_swiper').removeClass('hovered-next') }
)

$('#prev_dep').hover(
       function(){ $('.department_swiper').addClass('hovered-prev') },
       function(){ $('.department_swiper').removeClass('hovered-prev') }
)
// $( ".purchasableId" ).change(function(e) {
//   $(".purchasableInfo").hide();
//   $("#"+($(this).find(":selected").data('info'))).toggle();
// });

$(document).ready(() => {

  $('#show-onetime-form').click(function (event) {
    $('.form-tab').addClass('hidden');
    $('#form-tab-onetime').removeClass('hidden');
    $('#show-onetime-form').addClass('tabActive');
    $('#show-recurrent-form').removeClass('tabActive');
  });

  $('#show-recurrent-form').click(function (event) {
    $('.form-tab').addClass('hidden');
    $('#form-tab-recurrent').removeClass('hidden');
    $('#show-recurrent-form').addClass('tabActive');
    $('#show-onetime-form').removeClass('tabActive');
  });
})
