<template>
<swiper ref="swiper" class="swiper" :options="swiperOption" @slideChange="triggerOnChange">
  <slot></slot>
  <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
  <div class="swiper-button-prev" slot="button-prev"></div>
  <div class="swiper-button-next" slot="button-next"></div>
</swiper>
</template>

<script>
import {
  Swiper,
  SwiperSlide
} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'swiper-example-zoom',
  title: 'Zoom',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        zoom: true,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
},
methods: {
    triggerOnChange(ev) {
        if (this.$refs.swiper.$swiper) {
            this.$emit('slide-change', this.$refs.swiper.$swiper.activeIndex)
        } else {
            this.$emit('slide-change', 0)
        }
    }
}
}
</script>

<style lang="scss" scoped>
.swiper {
    height: 400px;

    .swiper-slide {
        background: #444;
    }
}
</style>
